import styled from 'styled-components';

export const HoverableButton = styled.button.attrs({
  className: 'ant-btn',
})`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 300;
  box-shadow: none;
  font-size: 15px;
  padding: 0;
  margin: 0;
  height: auto;

  :hover {
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :active {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :focus {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }

  :selected {
    order: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.black};
  }
`;
