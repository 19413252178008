import styled from 'styled-components';
import { Button as BaseButton } from '../atoms';
import { Layout as AntdLayout } from 'antd';

export const Layout = styled.div<{ $allowScroll: boolean | undefined }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: ${({ $allowScroll }) => ($allowScroll ? '' : 'auto')};
  height: ${({ $allowScroll }) => ($allowScroll ? ' calc(100vh - 72px)' : '')};

  .ant-layout-header {
    height: unset;
  }
`;

export const Card = styled.div<{ $allowScroll: boolean | undefined }>`
  position: relative;
  display: flex;
  flex: 0 auto;
  flex-direction: column;
  padding: 48px 0px 72px;
  border-radius: 18px;
  background: ${({ theme }) => theme.colors.white};
  margin: 36px;
  max-height: ${({ $allowScroll }) => ($allowScroll ? '100%' : '')};

  @media print {
    margin: 4px 0px;
    padding: 0px;
  }
`;

export const Header = styled(AntdLayout.Header)<{ $showBorder: boolean }>`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 888;
  background: ${({ theme }) => theme.colors.white};
  padding: 12px 72px;
  align-items: center;
  border-bottom: 1px solid
    ${({ theme, $showBorder }) =>
      $showBorder ? theme.colors.grey20 : 'transparent'};
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

export const Content = styled(AntdLayout.Content)<{
  $allowScroll: boolean | undefined;
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 48px 72px 0;
  overflow-y: ${({ $allowScroll }) => ($allowScroll ? 'auto' : '')};
`;

export const BackButton = styled(BaseButton)`
  @media print {
    display: none;
  }
`;
