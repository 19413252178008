import React from 'react';
import * as S from './styles';
import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';

export const Tooltip = (props: AntdTooltipProps) => {
  return (
    <AntdTooltip {...props}>
      <S.HoverableButton>{props.children}</S.HoverableButton>
    </AntdTooltip>
  );
};
